
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    export default {
        name: "TemplateContainer",
        props: {
            basket: {
                default: false,
                type: Boolean
            },
            edit: {
                default: false,
                type: Boolean
            },
            fonts: {
                default: () => ({}),
                type: Object
            },
            items: {
                default: 0,
                type: Number
            },
            page: {
                default: () => ({}),
                type: Object
            },
            products: {
                default: () => ([]),
                type: Array
            },
            show: {
                default: false,
                type: Boolean
            },
            theme: {
                default: () => ({}),
                type: Object
            },
            sitePreview: {
                default: false,
                type: Boolean
            }
        },
        computed: {
            pageUpdates() {
                let output = [
                    { icon: false, value: "black", target: "background", type: "color" },
                    { icon: false, value: "white", target: "background", type: "color" },
                ];

                if (this.theme.primary) {
                    output.unshift({
                        icon: false, value: "primary",
                        target: "background", type: "color"
                    });
                }

                if (this.theme.secondary) {
                    output.unshift({
                        icon: false, value: "secondary",
                        target: "background", type: "color"
                    });
                }

                if (this.theme.third) {
                    output.unshift({
                        icon: false, value: "third",
                        target: "background", type: "color"
                    });
                }

                return output;
            },
            heroUpdates() {
                let output = [
                    { icon: false, value: "black", target: "background", type: "color" },
                    { icon: false, value: "white", target: "background", type: "color" },
                ];

                if (this.theme.primary) {
                    output.unshift({
                        icon: false, value: "primary",
                        target: "background", type: "color"
                    });
                }

                if (this.theme.secondary) {
                    output.unshift({
                        icon: false, value: "secondary",
                        target: "background", type: "color"
                    });
                }

                if (this.theme.third) {
                    output.unshift({
                        icon: false, value: "third",
                        target: "background", type: "color"
                    });
                }

                return output;
            },
            headingUpdates() {
                let output = [
                    { icon: false, value: "black", target: "heading", type: "color" },
                    { icon: false, value: "white", target: "heading", type: "color" },
                ];

                if (this.theme.primary) {
                    output.unshift({
                        icon: false, value: "primary",
                        target: "heading", type: "color"
                    });
                }

                if (this.theme.secondary) {
                    output.unshift({
                        icon: false, value: "secondary",
                        target: "heading", type: "color"
                    });
                }

                if (this.theme.third) {
                    output.unshift({
                        icon: false, value: "third",
                        target: "heading", type: "color"
                    });
                }

                if (this.fonts.primary && this.fonts.primary.family) {
                    output.push({
                        icon: false, value: this.fonts.primary.family,
                        target: "heading", type: "font"
                    });
                }

                if (this.fonts.secondary && this.fonts.secondary.family) {
                    output.push({
                        icon: false, value: this.fonts.secondary.family,
                        target: "heading", type: "font"
                    });
                }

                if (this.fonts.third && this.fonts.third.family) {
                    output.push({
                        icon: false, value: this.fonts.third.family,
                        target: "heading", type: "font"
                    });
                }

                const fontCheck = output.filter(data => data.type === "font");

                if (fontCheck.length) {
                    output.push({
                        icon: false, value: null,
                        target: "heading", type: "font"
                    });
                }

                return output;
            },
            paraUpdates() {
                let output = [
                    { icon: false, value: "black", target: "paragraph", type: "color" },
                    { icon: false, value: "white", target: "paragraph", type: "color" },
                ];

                if (this.theme.primary) {
                    output.unshift({
                        icon: false, value: "primary",
                        target: "paragraph", type: "color"
                    });
                }

                if (this.theme.secondary) {
                    output.unshift({
                        icon: false, value: "secondary",
                        target: "paragraph", type: "color"
                    });
                }

                if (this.theme.third) {
                    output.unshift({
                        icon: false, value: "third",
                        target: "paragraph", type: "color"
                    });
                }

                if (this.fonts.primary && this.fonts.primary.family) {
                    output.push({
                        icon: false, value: this.fonts.primary.family,
                        target: "paragraph", type: "font"
                    });
                }

                if (this.fonts.secondary && this.fonts.secondary.family) {
                    output.push({
                        icon: false, value: this.fonts.secondary.family,
                        target: "paragraph", type: "font"
                    });
                }

                if (this.fonts.third && this.fonts.third.family) {
                    output.push({
                        icon: false, value: this.fonts.third.family,
                        target: "paragraph", type: "font"
                    });
                }

                const fontCheck = output.filter(data => data.type === "font");

                if (fontCheck.length) {
                    output.push({
                        icon: false, value: null,
                        target: "paragraph", type: "font"
                    });
                }

                return output;
            },
            footerUpdates() {
                let output = [
                    { icon: false, value: "black", target: "footer", type: "color" },
                    { icon: false, value: "white", target: "footer", type: "color" },
                ];

                if (this.theme.primary) {
                    output.unshift({
                        icon: false, value: "primary",
                        target: "footer", type: "color"
                    });
                }

                if (this.theme.secondary) {
                    output.unshift({
                        icon: false, value: "secondary",
                        target: "footer", type: "color"
                    });
                }

                if (this.theme.third) {
                    output.unshift({
                        icon: false, value: "third",
                        target: "footer", type: "color"
                    });
                }

                if (this.fonts.primary && this.fonts.primary.family) {
                    output.push({
                        icon: false, value: this.fonts.primary.family,
                        target: "footer", type: "font"
                    });
                }

                if (this.fonts.secondary && this.fonts.secondary.family) {
                    output.push({
                        icon: false, value: this.fonts.secondary.family,
                        target: "footer", type: "font"
                    });
                }

                if (this.fonts.third && this.fonts.third.family) {
                    output.push({
                        icon: false, value: this.fonts.third.family,
                        target: "footer", type: "font"
                    });
                }

                const fontCheck = output.filter(data => data.type === "font");

                if (fontCheck.length) {
                    output.push({
                        icon: false, value: null,
                        target: "footer", type: "font"
                    });
                }

                return output;
            }
        },
        methods: {
            hideEdit() {
                if (this.edit) this.show = false;
            },
            closeProductAddedToCartModal() {
                this.$store.commit('storeFront/UPDATE_HAS_JUST_ADDED_A_PRODUCT_TO_CART', false);
            },
            closeProductAddedToCartModalAndShowCart() {
                this.$store.commit('storeFront/UPDATE_HAS_JUST_ADDED_A_PRODUCT_TO_CART', false);
                this.$store.commit('basket/OPEN_CLOSE_BASKET');
            },
            showEdit() {
                if (this.edit) this.show = true;
            },
            updateEvent(obj) {
                this.$parent.$emit("update-event", obj);
            },
            updateHeading(val) {
                this.$parent.$emit("update-heading", val);
            },
            updateParagraph(val) {
                this.$parent.$emit("update-paragraph", val);
            },
            updateFooter(val) {
                this.$parent.$emit("update-footer", val);
            },
            updateShop(val) {
                this.$parent.$emit("update-shop", val);
            },
        }
    }
