import { render, staticRenderFns } from "./TemplateContainer.vue?vue&type=template&id=f4d592e0"
import script from "./TemplateContainer.vue?vue&type=script&lang=js"
export * from "./TemplateContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControlSite: require('/usr/src/eventum-portal/components/control/ControlSite.vue').default,ProductAddedToCartModal: require('/usr/src/eventum-portal/components/product/ProductAddedToCartModal.vue').default})
